import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import SEO from "../components/SEO";
import { PageSectionWrapper } from "../components/common";
import {
  InstructionsPageHeader,
  InstructionsPedagogicsSection,
  InstructionsThemeSection,
  InstructionsLanguage,
  InstructionsInfoListSection,
  InstructionsScienceSection,
} from "../components/InstructionsPage";

const InstructionsPageWrapper = styled(PageSectionWrapper)`
  h2 {
    color: black;
    margin-bottom: 40px;
  }
`;

export const InstructionsPageTemplate = ({
  title,
  instructionsHeader,
  instructionsSteps,
  instructionsPedagogics,
  instructionsLanguage,
  instructionsInfoList,
  instructionsScience,
}) => {
  return (
    <>
      <SEO title={title} />
      <InstructionsPageWrapper>
        <InstructionsPageHeader instructionsHeader={instructionsHeader} />
        <InstructionsThemeSection instructionsSteps={instructionsSteps} />
        <InstructionsPedagogicsSection
          instructionsPedagogics={instructionsPedagogics}
        />
        <InstructionsLanguage instructionsLanguage={instructionsLanguage} />
        <InstructionsInfoListSection
          instructionsInfoList={instructionsInfoList}
        />

        <InstructionsScienceSection instructionsScience={instructionsScience} />
      </InstructionsPageWrapper>
    </>
  );
};

const InstructionsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <InstructionsPageTemplate
      title={frontmatter.title}
      instructionsSteps={frontmatter.instructionsSteps}
      instructionsHeader={frontmatter.instructionsHeader}
      instructionsPedagogics={frontmatter.instructionsPedagogics}
      instructionsLanguage={frontmatter.instructionsLanguage}
      instructionsInfoList={frontmatter.instructionsInfoList}
      instructionsScience={frontmatter.instructionsScience}
    />
  );
};

export default InstructionsPage;

export const instructionsPageQuery = graphql`
  query InstructionsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        instructionsHeader {
          instructionsHeading
          body
          instructionsSubHeading
          instructionsAnswerList {
            instructionsAnswerListImage {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altText
            instructionsAnswerListHeading
            instructionsAnswerListDescription
          }
        }
        instructionsSteps {
          instructionsStepsHeading
          instructionsStepsPreamble
          instructionsStepsImage {
            childImageSharp {
              fluid(maxWidth: 1050, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          altText
          instructionsStepsList {
            instructionsStepsListHeading
            instructionsStepsListDescription
            instructionsStepsListImage {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altText
          }
        }
        instructionsPedagogics {
          instructionsPedagogicsHeading
          instructionsPedagogicsList {
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altText
            heading
            preamble
            body
          }
        }
        instructionsLanguage {
          header {
            heading
            body
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altText
          }
          article1 {
            heading
            body
          }
          article2 {
            heading
            body
          }
        }
        instructionsInfoList {
          heading
          description
          image {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          altText
        }
        instructionsScience {
          instructionsScienceHeading
          instructionsScienceList {
            heading
            body
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altText
          }
          instructionsScienceFooter {
            heading
            body
          }
        }
      }
    }
  }
`;
