import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import {
  ImageWrapperCircle,
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
  PanelList,
  PanelListItem,
  PanelListItemLink,
  PanelListItemText,
  TextEditor,
} from "../common";
import { toHTML } from "../../utils/helpers";

const InstructionsPedagogicsDescription = styled.div`
  max-width: 900px;
  margin-bottom: 40px;

  p {
    font-weight: 400;
  }

  h3 {
    margin-bottom: 12px;
  }

  p {
    font-size: 18px;
  }
`;

const InstructionsPedagogicsArticle = styled(PageSectionArticle)`
  h2 {
    padding-top: 50px;
  }
`;

export const InstructionsPedagogicsSection = ({ instructionsPedagogics }) => {
  const {
    instructionsPedagogicsHeading,
    instructionsPedagogicsList,
  } = instructionsPedagogics;
  return (
    <PageSection bgColor="var(--pmbMysticLight)">
      <PageSectionBackdrop>
        <InstructionsPedagogicsArticle>
          <h2>{instructionsPedagogicsHeading}</h2>
          <PanelList height="400px">
            {!!instructionsPedagogicsList &&
              instructionsPedagogicsList.map(
                ({ image, heading, altText, preamble }, i) => (
                  <PanelListItem
                    listLength={instructionsPedagogicsList.length}
                    key={i}
                  >
                    <PanelListItemLink href={`#instructionsPedagogics-${i}`}>
                      <ImageWrapperCircle size="130px">
                        <Img
                          fluid={image.childImageSharp.fluid}
                          alt={altText}
                        />
                      </ImageWrapperCircle>
                      <PanelListItemText>
                        <h4>{heading}</h4>
                        <p>{preamble}</p>
                      </PanelListItemText>
                    </PanelListItemLink>
                  </PanelListItem>
                )
              )}
          </PanelList>
          {!!instructionsPedagogicsList &&
            instructionsPedagogicsList.map(({ heading, body }, i) => (
              <InstructionsPedagogicsDescription
                key={i}
                id={`instructionsPedagogics-${i}`}
              >
                <h3>{heading}</h3>
                <TextEditor
                  dangerouslySetInnerHTML={{ __html: toHTML(body) }}
                />
              </InstructionsPedagogicsDescription>
            ))}
        </InstructionsPedagogicsArticle>
      </PageSectionBackdrop>
    </PageSection>
  );
};
