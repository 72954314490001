import React from "react";
import Img from "gatsby-image";
import { toHTML } from "../../utils/helpers";
import {
  PageSection,
  PageSectionBackdrop,
  PanelList,
  PanelListItemText,
  PanelListItem,
  ImageWrapperCircle,
  PanelListItemContent,
  TextEditor,
  PageSectionHeaderText,
  PageSectionHeader,
} from "../common";
import { PageHeaderImage } from "../common/PageHeaderImage";

export const InstructionsPageHeader = ({ instructionsHeader }) => {
  const {
    instructionsHeading,
    body,
    instructionsSubHeading,
    instructionsAnswerList,
  } = instructionsHeader;
  return (
    <PageSection bgColor="var(--pmbMystic)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <PageSectionHeader>
          <PageHeaderImage />
          <PageSectionHeaderText>
            {!!instructionsHeading && <h1>{instructionsHeading}</h1>}
            <TextEditor dangerouslySetInnerHTML={{ __html: toHTML(body) }} />
          </PageSectionHeaderText>
          <h2>{instructionsSubHeading}</h2>
          <PanelList height="560px">
            {!!instructionsAnswerList &&
              instructionsAnswerList.map(
                (
                  {
                    instructionsAnswerListImage,
                    instructionsAnswerListHeading,
                    instructionsAnswerListDescription,
                    altText,
                  },
                  i
                ) => (
                  <PanelListItem
                    key={i}
                    listLength={instructionsAnswerList.length}
                    bgColor="var(--pmbMysticTwo)"
                  >
                    <PanelListItemContent>
                      <ImageWrapperCircle size="180px">
                        {instructionsAnswerListImage && (
                          <Img
                            fluid={
                              instructionsAnswerListImage.childImageSharp.fluid
                            }
                            alt={altText}
                          />
                        )}
                      </ImageWrapperCircle>
                      <PanelListItemText>
                        <h4>{instructionsAnswerListHeading}</h4>
                        <p>{instructionsAnswerListDescription}</p>
                      </PanelListItemText>
                    </PanelListItemContent>
                  </PanelListItem>
                )
              )}
          </PanelList>
        </PageSectionHeader>
      </PageSectionBackdrop>
    </PageSection>
  );
};
