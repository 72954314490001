import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { toHTML } from "../../utils/helpers";
import {
  ImageWrapperRectangle,
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
  TextEditor,
} from "../common";
import { respondTo } from "../../styles";

const InstructionsScienceContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;

  ${respondTo.lg`
    flex-direction: row;
  `}
`;

const InstructionsScienceContentBox = styled.div`
  width: 100%;

  ${respondTo.lg`
    width: 45%;
  `}

  h3 {
    color: var(--pmbCrete);
  }

  ${ImageWrapperRectangle} {
    margin-bottom: 32px;
  }
`;

const InstructionsScienceArticle = styled(PageSectionArticle)`
  padding-top: 50px;

  h3 {
    margin-bottom: 16px;
  }
`;

export const InstructionsScienceSection = ({ instructionsScience }) => {
  const {
    instructionsScienceHeading,
    instructionsScienceList,
    instructionsScienceFooter,
  } = instructionsScience;

  return (
    <>
      <PageSection bgColor="var(--creamWhite)">
        <PageSectionBackdrop bgColor="var(--infoBg)">
          <InstructionsScienceArticle>
            <h2>{instructionsScienceHeading}</h2>
            <InstructionsScienceContent>
              {!!instructionsScienceList &&
                instructionsScienceList.map(
                  ({ heading, body, image, altText }, i) => (
                    <InstructionsScienceContentBox key={i}>
                      <ImageWrapperRectangle>
                        {!!image && (
                          <Img
                            fluid={image.childImageSharp.fluid}
                            alt={altText}
                          />
                        )}
                      </ImageWrapperRectangle>
                      <h3>{heading}</h3>
                      <TextEditor
                        dangerouslySetInnerHTML={{ __html: toHTML(body) }}
                      />
                    </InstructionsScienceContentBox>
                  )
                )}
            </InstructionsScienceContent>
          </InstructionsScienceArticle>
        </PageSectionBackdrop>
      </PageSection>
      <PageSection bgColor="var(--infoBg)">
        <PageSectionBackdrop bgColor="var(--creamWhite)">
          <InstructionsScienceArticle>
            <h3>{instructionsScienceFooter.heading}</h3>
            <TextEditor
              dangerouslySetInnerHTML={{
                __html: toHTML(instructionsScienceFooter.body),
              }}
            />
          </InstructionsScienceArticle>
        </PageSectionBackdrop>
      </PageSection>
    </>
  );
};
