import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import Cloud from "../../images/PMB_cloud_instructions.png";
import Stones from "../../images/PMB_stones_instructions.png";
import Hills from "../../images/PMB_hills_instructions.png";
import { toHTML } from "../../utils/helpers";
import {
  ImageWrapperRectangle,
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
  TextEditor,
} from "../common";
import { respondTo } from "../../styles";

const InstructionsPedagagicsBackdrop = styled(PageSectionBackdrop)`
  overflow: hidden;

  &:before {
    content: none;
    position: absolute;
    background-image: url(${Hills});
    background-repeat: no-repeat;
    background-size: contain;
    height: 350px;
    width: 350px;
    right: 150px;
    bottom: -180px;

    ${respondTo.lg`
      content: '';
    `}
  }

  &:after {
    content: none;
    position: absolute;
    background-image: url(${Stones});
    background-repeat: no-repeat;
    background-size: contain;
    height: 380px;
    width: 350px;
    left: 150px;
    bottom: -60px;

    ${respondTo.lg`
      content: '';
    `}
  }
`;

const InstructionsArticle = styled(PageSectionArticle)`
  &:before {
    content: none;
    position: absolute;
    background-image: url(${Cloud});
    background-repeat: no-repeat;
    background-size: contain;
    height: 240px;
    width: 400px;
    left: -130px;
    top: 200px;

    ${respondTo.lg`
      content: '';
    `}
  }

  &:after {
    content: none;
    position: absolute;
    background-image: url(${Cloud});
    background-repeat: no-repeat;
    background-size: contain;
    height: 150px;
    width: 260px;
    right: 0;
    bottom: 260px;

    ${respondTo.lg`
      content: '';
    `}
  }
`;

const InstructionsArticleContent = styled.div`
  margin: 0 auto 60px;

  ${respondTo.lg`
    width: 530px;

    &:first-of-type {
      padding-top: 60px;
    }
  `}

  h3 {
    margin-bottom: 22px;
  }
`;

export const InstructionsLanguage = ({ instructionsLanguage }) => {
  const { header, article1, article2 } = instructionsLanguage;

  return (
    <PageSection bgColor="var(--creamWhite)">
      <InstructionsPedagagicsBackdrop bgColor="var(--pmbMysticLight)">
        <InstructionsArticle>
          <InstructionsArticleContent>
            <h3>{header.heading}</h3>
            <TextEditor
              dangerouslySetInnerHTML={{ __html: toHTML(header.body) }}
            />
            <ImageWrapperRectangle>
              <Img
                fluid={header.image.childImageSharp.fluid}
                alt={header.altText}
              />
            </ImageWrapperRectangle>
          </InstructionsArticleContent>
          <InstructionsArticleContent>
            <h3>{article1.heading}</h3>
            <TextEditor
              dangerouslySetInnerHTML={{ __html: toHTML(article1.body) }}
            />
          </InstructionsArticleContent>
          <InstructionsArticleContent>
            <h3>{article2.heading}</h3>
            <TextEditor
              dangerouslySetInnerHTML={{ __html: toHTML(article2.body) }}
            />
          </InstructionsArticleContent>
        </InstructionsArticle>
      </InstructionsPedagagicsBackdrop>
    </PageSection>
  );
};
