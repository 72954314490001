import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";

import {
  CounterIcon,
  ImageWrapperRectangle,
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
} from "../common";
import {
  InstructionsCounterList,
  InstructionsCounterListItem,
  InstructionsCounterListItemText,
} from "./InstructionsCounterList";
import { respondTo } from "../../styles";

const InstructionsThemeArticleText = styled.div`
  max-width: 900px;
  margin-bottom: 40px;
  padding-top: 50px;
`;

const InstructionsThemeImage = styled.div`
  width: 100%;
  height: auto;
  max-width: 1050px;
  margin-bottom: 90px;

  ${respondTo.lg`
  height: 200px;
  `}
`;
export const InstructionsThemeSection = ({ instructionsSteps }) => {
  const {
    instructionsStepsHeading,
    instructionsStepsPreamble,
    instructionsStepsImage,
    altText,
    instructionsStepsList,
  } = instructionsSteps;
  console.log("bild", instructionsStepsImage);

  return (
    <PageSection bgColor="var(--pmbJungleMist)">
      <PageSectionBackdrop bgColor="var(--pmbMystic)">
        <PageSectionArticle>
          <InstructionsThemeArticleText>
            <h2>{instructionsStepsHeading}</h2>
            <p>{instructionsStepsPreamble}</p>
          </InstructionsThemeArticleText>

          <InstructionsThemeImage>
            <Img
              fluid={instructionsStepsImage.childImageSharp.fluid}
              alt={altText}
            />
          </InstructionsThemeImage>

          <InstructionsCounterList counterName="InstructionsThemeList">
            {!!instructionsStepsList &&
              instructionsStepsList.map(
                (
                  {
                    instructionsStepsListHeading,
                    instructionsStepsListDescription,
                    instructionsStepsListImage,
                    altText,
                  },
                  i
                ) => (
                  <InstructionsCounterListItem key={i}>
                    <CounterIcon
                      bgColor="var(--pmbChestnut)"
                      counterName="InstructionsThemeList"
                    />
                    <InstructionsCounterListItemText>
                      <h3>{instructionsStepsListHeading}</h3>
                      <p>{instructionsStepsListDescription}</p>
                    </InstructionsCounterListItemText>
                    <ImageWrapperRectangle width="300px" height="auto">
                      <Img
                        fluid={instructionsStepsListImage.childImageSharp.fluid}
                        alt={altText}
                      />
                    </ImageWrapperRectangle>
                  </InstructionsCounterListItem>
                )
              )}
          </InstructionsCounterList>
        </PageSectionArticle>
      </PageSectionBackdrop>
    </PageSection>
  );
};
