import styled from "styled-components";
import { respondTo } from "../../styles";

export const InstructionsCounterList = styled.ul`
  list-style: none;
  counter-reset: ${(props) => props.counterName || "none"};
  max-width: 1050px;
`;
export const InstructionsCounterListItem = styled.li`
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 80px auto auto;
  row-gap: 30px;

  ${respondTo.lg`
    grid-template-columns: 80px auto 300px;
    grid-template-rows: 100%;
    row-gap: 0;
  `}
`;
export const InstructionsCounterListItemText = styled.div`
  display: flex;
  flex-direction: column;

  ${respondTo.lg`
    padding: 0 40px;
    width: 650px;
  `}

  h3 {
    margin-bottom: 16px;
  }
`;
