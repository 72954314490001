import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import {
  CounterIcon,
  ImageWrapperRectangle,
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
} from "../common";
import {
  InstructionsCounterList,
  InstructionsCounterListItem,
  InstructionsCounterListItemText,
} from "./InstructionsCounterList";

const InstructionsInfoList = styled(InstructionsCounterList)`
  padding-top: 60px;
`;

export const InstructionsInfoListSection = ({ instructionsInfoList }) => {
  return (
    <PageSection bgColor="var(--infoBg)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <PageSectionArticle>
          <InstructionsInfoList counterName="InstructionsInfoList">
            {!!instructionsInfoList &&
              instructionsInfoList.map(
                ({ heading, description, image, altText }, i) => (
                  <InstructionsCounterListItem key={i}>
                    <CounterIcon
                      counterName="InstructionsInfoList"
                      bgColor="var(--pmbCoffee)"
                    />
                    <InstructionsCounterListItemText>
                      <h3>{heading}</h3>
                      <p>{description}</p>
                    </InstructionsCounterListItemText>
                    <ImageWrapperRectangle width="300px">
                      <Img fluid={image.childImageSharp.fluid} alt={altText} />
                    </ImageWrapperRectangle>
                  </InstructionsCounterListItem>
                )
              )}
          </InstructionsInfoList>
        </PageSectionArticle>
      </PageSectionBackdrop>
    </PageSection>
  );
};
